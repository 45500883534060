'use client';
import { Suspense } from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CircleNotch, Minus, Plus, Trash } from '@phosphor-icons/react';
import { IKButton } from '@/components/shared/material';
import { CartCostBreakdown, DiscountForm } from '@/components/purchase-journey';
import { useAtom, useAtomValue } from 'jotai';
import useCart from '@/hooks/useCart';
import { useSearchParams } from 'next/navigation';
import {
  isCartSlideOutOpenAtom,
  cartAtom,
  cartItemsQuantityAtom,
  isAddingOneAtom,
  isRemovingOneItemAtom,
  isRemovingLineItemAtom,
} from '@/store/cartStore';
import Image from 'next/image';
import { formatPrice } from '@/sanity/utils/shopify/priceHelpers';
import ToastContent from '@/components/toasts/ToastContent';

import { toast as toastify, ToastContainer } from 'react-toastify';
import { testIds } from '@/constants/testIds';

function SlideOut() {
  const { removeItem, updateItem, fetchCart, addDiscount, productIsDebitCard } =
    useCart();
  const cart = useAtomValue(cartAtom);
  const cartItemsQuantity = useAtomValue(cartItemsQuantityAtom);
  const [open, setOpen] = useAtom(isCartSlideOutOpenAtom);
  const [isLoaded, setIsLoaded] = useState(false);
  const [toastShown, setToastShown] = useState(false);

  // Loaders
  const isAddingOneItem = useAtomValue(isAddingOneAtom);
  const isRemovingOneItem = useAtomValue(isRemovingOneItemAtom);
  const isRemovingLineItem = useAtomValue(isRemovingLineItemAtom);

  const searchParams = useSearchParams();
  const discountCode = searchParams.get('discountCode');

  const isCartUpdating =
    isAddingOneItem || isRemovingOneItem || isRemovingLineItem;

  function calculateTotal(price: number, quantity: number) {
    return formatPrice(price * quantity);
  }

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      fetchCart();
    }
  }, [isLoaded]);

  useEffect(() => {
    const applyDiscount = async () => {
      if (discountCode && cart?.lineItems) {
        const response = await addDiscount(discountCode);

        if (toastShown) {
          return null;
        }

        if (response.success) {
          toastify.info(
            ToastContent({
              toast: {
                message: response.message,
              },
            }),
            {
              containerId: 'cart-toast-container',
            }
          );
          setToastShown(true);
        }
      }
    };

    applyDiscount();
  }, [discountCode, cart?.lineItems, toastShown]);

  const isFetching = useRef(false);

  // show quantity buttons
  const showQuantityButtons = (lineItem: any) => {
    if (productIsDebitCard(lineItem)) {
      return false;
    }
    return true;
  };

  return (
    <>
      <ToastContainer
        position='bottom-left'
        autoClose={5000}
        draggable
        icon={false}
        theme='light'
        className={`!z-400 md:!w-[350px]`}
        toastClassName={`!bg-gradient-to-r  !bg-gradient-teal !text-white !shadow !shadow-lg`}
        progressClassName={`!bg-charcoal`}
        containerId='cart-toast-container'
      />
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative !z-700' onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter='ease-in-out duration-500'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in-out duration-500'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-charcoal bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-hidden'>
            <div className='absolute inset-0 overflow-hidden'>
              <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
                <Transition.Child
                  as={Fragment}
                  enter='transform transition ease-in-out duration-500 sm:duration-700'
                  enterFrom='translate-x-full'
                  enterTo='translate-x-0'
                  leave='transform transition ease-in-out duration-500 sm:duration-700'
                  leaveFrom='translate-x-0'
                  leaveTo='translate-x-full'
                >
                  <Dialog.Panel className='pointer-events-auto w-screen max-w-md'>
                    <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl [-webkit-scrollbar-thumb:_border]'>
                      <div className='flex-1 overflow-y-auto px-4 py-6 sm:px-6'>
                        <div className='sticky -top-6 z-20 -mt-6 flex items-start justify-between bg-white pb-2 pt-6'>
                          <Dialog.Title className='text-lg font-medium'>
                            Cart{' '}
                            {!isFetching.current && (
                              <span className='font-light'>
                                <>
                                  ({cartItemsQuantity}{' '}
                                  {cartItemsQuantity > 1 ? 'items' : 'item'})
                                </>
                              </span>
                            )}
                          </Dialog.Title>
                          <div className='ml-3 flex h-7 items-center'>
                            <button
                              type='button'
                              data-test-qa={testIds.cartCloseSlideOut}
                              className='relative -m-2 p-2 font-bold text-teal hover:text-teal-700'
                              onClick={() => setOpen(false)}
                            >
                              <span className='absolute -inset-0.5' />
                              <span className='sr-only'>Close panel</span>
                              Close
                            </button>
                          </div>
                        </div>
                        {/* Product list */}
                        <div className='mt-8'>
                          <div className='flow-root'>
                            {/* Skeleton of product card */}
                            {isFetching.current && (
                              <ul
                                role='list'
                                className='-my-6 animate-pulse divide-y divide-gray-200'
                              >
                                <li className='flex py-6'>
                                  <div className='h-20 w-20 flex-shrink-0 overflow-hidden rounded-md border bg-gray-200'></div>
                                  <div className='ml-4 flex flex-1 flex-col'>
                                    <div>
                                      <div className='flex justify-between text-base font-medium'>
                                        <div className='h-6 w-1/2 rounded bg-gray-200 text-lg'></div>
                                        <div className='ml-4 h-6 w-6 rounded bg-gray-200'></div>
                                      </div>
                                    </div>
                                    <div className='flex flex-1 items-end justify-between text-sm'>
                                      <div>
                                        <div className='my-1 h-4 w-6 rounded bg-gray-200'></div>
                                        <div className='flex gap-2'>
                                          <div className='h-6 w-6 flex-1 items-center rounded bg-gray-200'></div>
                                          <div className='h-6 w-6 flex-1 select-none items-center rounded bg-gray-200'></div>
                                          <div className='h-6 w-6 flex-1 items-center rounded bg-gray-200'></div>
                                        </div>
                                      </div>
                                      <div className='flex h-6 w-1/4 rounded bg-gray-200'></div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            )}
                            <ul
                              role='list'
                              className='-my-6 divide-y divide-gray-200'
                            >
                              {cart?.lineItems.map((lineItem) => (
                                <li
                                  key={lineItem.product.id}
                                  className={`flex py-6 ${
                                    isRemovingLineItem &&
                                    isRemovingLineItem === lineItem.id
                                      ? 'pointer-events-none animate-pulse'
                                      : ''
                                  }`}
                                >
                                  <div className='h-20 w-20 flex-shrink-0 overflow-hidden rounded-md border border-yellow bg-yellow'>
                                    <Image
                                      src={lineItem.product?.previewImageUrl}
                                      alt={lineItem.product.title}
                                      width={50}
                                      height={50}
                                      className='h-full w-full object-cover object-center'
                                    />
                                  </div>

                                  <div className='ml-4 flex flex-1 flex-col'>
                                    <div>
                                      <div className='flex justify-between text-base font-medium'>
                                        <h3 className='text-lg font-bold'>
                                          {lineItem.product.title}
                                        </h3>
                                        <button
                                          disabled={isCartUpdating !== false}
                                          title='Remove from cart'
                                          type='button'
                                          onClick={() => {
                                            removeItem(lineItem.id);
                                          }}
                                          className={`ml-4 font-medium ${isCartUpdating !== false ? '!cursor-wait opacity-80' : ''}`}
                                        >
                                          <span className='sr-only'>
                                            {`Remove ${lineItem.product.title} from cart`}
                                          </span>
                                          {isRemovingLineItem &&
                                          isRemovingLineItem === lineItem.id ? (
                                            <CircleNotch
                                              weight='bold'
                                              className='mx-auto animate-spin'
                                            />
                                          ) : (
                                            <Trash
                                              weight='regular'
                                              className='h-6 w-6'
                                            />
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      data-test-qa={
                                        testIds.cartQuantityAndPrice
                                      }
                                      className={`flex flex-1 justify-between text-sm ${showQuantityButtons(lineItem) ? 'items-end' : 'items-center'}`}
                                    >
                                      <div
                                        className={` ${!showQuantityButtons(lineItem) ? 'flex items-center gap-4' : ''}`}
                                      >
                                        <div className='pb-1'>Qty:</div>
                                        <span className='flex gap-2'>
                                          {showQuantityButtons(lineItem) && (
                                            <button
                                              disabled={
                                                isCartUpdating !== false ||
                                                lineItem.quantity === 1
                                              }
                                              title='Remove one from cart'
                                              type='button'
                                              onClick={() => {
                                                updateItem(lineItem.id, -1);
                                              }}
                                              className={`flex-1 items-center rounded px-2 py-1 text-sm font-semibold focus:z-10 ${
                                                lineItem.quantity === 1
                                                  ? 'bg-gray-100 hover:bg-gray-200'
                                                  : 'bg-yellow-100 hover:bg-yellow-200'
                                              } ${isCartUpdating !== false ? '!cursor-wait opacity-80' : ''}`}
                                            >
                                              <span className='sr-only'>
                                                {`Remove one ${lineItem.product.title} from cart`}
                                              </span>

                                              {isRemovingOneItem &&
                                              isRemovingOneItem ===
                                                lineItem.id ? (
                                                <CircleNotch
                                                  weight='bold'
                                                  className='mx-auto animate-spin'
                                                />
                                              ) : (
                                                <Minus weight='bold' />
                                              )}
                                            </button>
                                          )}
                                          <div className='flex-1 select-none items-center rounded bg-yellow-100 px-3 py-1 text-sm font-semibold'>
                                            {lineItem.quantity}
                                          </div>
                                          {showQuantityButtons(lineItem) && (
                                            <button
                                              disabled={
                                                isCartUpdating !== false
                                              }
                                              title='Add one to cart'
                                              type='button'
                                              onClick={() => {
                                                updateItem(lineItem.id, 1);
                                              }}
                                              className={`flex-1 items-center rounded bg-yellow-100 px-2 py-1 text-sm font-semibold hover:bg-yellow-200 focus:z-10 ${isCartUpdating !== false ? '!cursor-wait opacity-80' : ''}`}
                                            >
                                              <span className='sr-only'>
                                                {`Add one ${lineItem.product.title} to cart`}
                                              </span>
                                              {isAddingOneItem &&
                                              (isAddingOneItem ===
                                                lineItem.id ||
                                                (Array.isArray(
                                                  isAddingOneItem
                                                ) &&
                                                  isAddingOneItem.includes(
                                                    lineItem.id
                                                  ))) ? (
                                                <>
                                                  <CircleNotch
                                                    weight='bold'
                                                    className='mx-auto animate-spin'
                                                  />
                                                </>
                                              ) : (
                                                <Plus weight='bold' />
                                              )}
                                            </button>
                                          )}
                                        </span>
                                      </div>

                                      <div className='flex text-lg font-bold'>
                                        {(isAddingOneItem &&
                                          isAddingOneItem === lineItem.id) ||
                                        (Array.isArray(isAddingOneItem) &&
                                          isAddingOneItem.includes(
                                            lineItem.id
                                          )) ||
                                        (isRemovingOneItem &&
                                          isRemovingOneItem === lineItem.id) ? (
                                          <>
                                            <div className='h-[24px] w-[120px] animate-pulse rounded-lg bg-gray-300 py-1'></div>
                                          </>
                                        ) : (
                                          <>
                                            {calculateTotal(
                                              lineItem.product.price,
                                              lineItem.quantity
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>

                      {/* Bottom section */}
                      <div
                        data-test-qa={testIds.cartDiscountAndTotal}
                        className='border-t border-gray-200 px-4 pb-6 sm:px-6'
                      >
                        {cartItemsQuantity > 0 && (
                          <>
                            <DiscountForm />
                            <CartCostBreakdown displayType={'cart'} />

                            <div className='mt-6 flex justify-center text-center text-sm text-gray-500 *:w-full'>
                              <IKButton
                                link={{
                                  url: 'purchase-journey',
                                  type: 'internal',
                                  document: 'page',
                                }}
                                data-test-qa={testIds.purchaseJourneyCheckout}
                                className='w-full'
                                color='yellow'
                              >
                                Proceed to checkout
                              </IKButton>
                            </div>
                          </>
                        )}
                        <div className='py-4'>
                          <div className='flex items-center justify-center gap-2'>
                            {/* Shield with center lock */}
                            <svg
                              width='24'
                              height='25'
                              viewBox='0 0 24 25'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M21.971 0H2.02844C1.49953 0 0.99228 0.208922 0.618284 0.580805C0.244288 0.952688 0.0341797 1.45707 0.0341797 1.98299V9.26677C0.0341797 20.3728 9.48447 24.0574 11.3765 24.6833C11.7806 24.8199 12.2188 24.8199 12.6229 24.6833C14.5175 24.0574 23.9653 20.3728 23.9653 9.26677V1.98299C23.9653 1.45707 23.7552 0.952688 23.3812 0.580805C23.0072 0.208922 22.4999 0 21.971 0Z'
                                fill='#1D1D1B'
                              />
                              <path
                                d='M16.5024 8.73465H15.3767V7.32755C15.3767 6.50654 15.0505 5.71916 14.47 5.13862C13.8894 4.55808 13.1021 4.23193 12.2811 4.23193H11.7182C10.8972 4.23193 10.1098 4.55808 9.52929 5.13862C8.94874 5.71916 8.6226 6.50654 8.6226 7.32755V8.73465H7.49692C7.34765 8.73465 7.20449 8.79395 7.09893 8.8995C6.99338 9.00505 6.93408 9.14821 6.93408 9.29749V16.0516C6.93408 16.2008 6.99338 16.344 7.09893 16.4496C7.20449 16.5551 7.34765 16.6144 7.49692 16.6144H16.5024C16.6516 16.6144 16.7948 16.5551 16.9003 16.4496C17.0059 16.344 17.0652 16.2008 17.0652 16.0516V9.29749C17.0652 9.14821 17.0059 9.00505 16.9003 8.8995C16.7948 8.79395 16.6516 8.73465 16.5024 8.73465ZM12.5343 13.0685V13.7721C12.5343 13.9213 12.475 14.0645 12.3695 14.1701C12.2639 14.2756 12.1208 14.3349 11.9715 14.3349C11.8222 14.3349 11.6791 14.2756 11.5735 14.1701C11.468 14.0645 11.4087 13.9213 11.4087 13.7721V13.0685C11.1941 12.9446 11.0263 12.7534 10.9315 12.5244C10.8367 12.2955 10.82 12.0417 10.8842 11.8023C10.9483 11.5629 11.0896 11.3514 11.2862 11.2006C11.4828 11.0497 11.7237 10.968 11.9715 10.968C12.2193 10.968 12.4602 11.0497 12.6568 11.2006C12.8534 11.3514 12.9947 11.5629 13.0588 11.8023C13.123 12.0417 13.1063 12.2955 13.0115 12.5244C12.9167 12.7534 12.7489 12.9446 12.5343 13.0685ZM14.251 8.73465H9.74828V7.32755C9.74828 6.80509 9.95583 6.30403 10.3253 5.93459C10.6947 5.56516 11.1958 5.35761 11.7182 5.35761H12.2811C12.8035 5.35761 13.3046 5.56516 13.674 5.93459C14.0434 6.30403 14.251 6.80509 14.251 7.32755V8.73465Z'
                                fill='#F7F7F7'
                              />
                            </svg>
                            <span className='text-base font-medium'>
                              Your transaction is secure
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default function CartSlideOut() {
  return (
    <>
      <Suspense>
        <SlideOut />
      </Suspense>
    </>
  );
}
